import {
  ANALYTICS_COLORS_PALETTE,
  ANALYTICS_NAVIGATION_TYPES,
  LEGEND_TYPES,
} from "@/core/types/components/enums";
import type {
  IAnalyticsLegends,
  IAnalyticsNavigation,
} from "@/core/types/components/interfaces";

export const ANALYTICS_NAVIGATION_CONTROLS: IAnalyticsNavigation[] = [
  {
    title: "Parameters",
    type: ANALYTICS_NAVIGATION_TYPES.PARAMETERS,
  },
  {
    title: "Connect",
    type: ANALYTICS_NAVIGATION_TYPES.CONNECTION,
  },
  {
    title: "Changing parameters",
    type: ANALYTICS_NAVIGATION_TYPES.CHANGE_PARAMETERS,
  },
  {
    title: "Operating mode",
    type: ANALYTICS_NAVIGATION_TYPES.OPERATION_MODE,
  },
];

export const ANALYTICS_LEGEND_ITEMS: IAnalyticsLegends = {
  [LEGEND_TYPES.CHANGE_PARAMETERS]: [
    {
      name: "Automatic mode",
      color: ANALYTICS_COLORS_PALETTE.LIGHT_BLUE,
    },
    {
      name: "Manual mode",
      color: ANALYTICS_COLORS_PALETTE.BLUE,
    },
  ],
  [LEGEND_TYPES.CONNECTION]: [
    {
      name: "Connected",
      color: ANALYTICS_COLORS_PALETTE.GREEN,
    },
  ],
  [LEGEND_TYPES.OPERATION_MODE]: [
    {
      name: "Automatic mode",
      color: ANALYTICS_COLORS_PALETTE.LIGHT_BLUE,
    },
    {
      name: "Manual mode",
      color: ANALYTICS_COLORS_PALETTE.BLUE,
    },
  ],
};
