import { createApp } from 'vue'
import './style.scss'
import '@mdi/font/css/materialdesignicons.min.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import 'vuetify/styles';
import VCalendar from 'v-calendar';
//@ts-ignore
import VueApexCharts from "vue3-apexcharts";

import 'v-calendar/style.css';

const app = createApp(App);

app.use(router);
app.use(VCalendar, {});
app.use(VueApexCharts);
app.use(createPinia());

app.mount('#app');
