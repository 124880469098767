import { defineStore } from "pinia";
import type {
  State,
  Getters,
  Actions,
} from "@/core/types/stores/container_store.interface";
import { useAuthStore } from "./authStore";
import { ContainersService } from "@/services";
import { AUTO_MODE, CMD } from "@/core/types/api/enums";
import { CONTAINER_ITEM_TYPE, STATUS } from "@/core/types/components/enums";

export const useContainerStore = defineStore<
  "container",
  State,
  Getters,
  Actions
>("container", {
  state: () => ({
    containerIdsArray: [],
    containers: [],
    singleContainer: null,
    currentMode: 0,
    autoModeData: null,
    manualModeData: null,
    currentUserMode: 0,
    autoUserModelData: null,
    manualUserModelData: null,
    pin: undefined,
    activeContainerId: null,
    isConnected: undefined,
    connectionNumber: "",
  }),
  getters: {
    // filter additional parameters object from empty values
    getAdditionalExistingParameters: (state) => {
      if (state.autoModeData === null) return null;

      return Object.fromEntries(
        Object.entries(state.autoModeData).filter(
          (entry) => entry[1] !== undefined
        )
      );
    },
  },
  actions: {
    resetStore() {
      this.$reset();
    },
    setCurrentMode(newVal) {
      this.currentUserMode = newVal;
    },
    setActiveContainerId(id) {
      this.activeContainerId = id;
    },
    setPinCode(newPin) {
      this.pin = newPin;
    },
    setNewAdditionalParameterValue(parameterKey, newValue) {
      if (this.autoUserModelData === null) return;

      this.autoUserModelData[parameterKey] = newValue;
    },
    setNewManualParameterValue(parameterKey, newValue) {
      if (this.manualUserModelData === null) return;

      this.manualUserModelData[parameterKey] = newValue;
    },
    setUserParameters(userMode, manualParams, autoParams) {
      (this.currentUserMode = userMode),
        (this.manualUserModelData = { ...manualParams });

      this.autoUserModelData = { ...autoParams };
    },
    setParameters(res) {
      if (!res) return;

      (this.currentMode =
        res.current_mode.auto_mode === AUTO_MODE.MANUAL ? 0 : 1),
        (this.manualModeData = {
          fanSpeed: res.current_mode.fan_speed,
          pump1Freq: res.current_mode.pump1_freq,
          pump2Freq: res.current_mode.pump2_freq,
        });

      this.autoModeData = {
        FAN_SPEED_MAX: res.current_auto_param.FAN_SPEED_MAX,
        FAN_SPEED_MIN: res.current_auto_param.FAN_SPEED_MIN,
        FAN_T_MAX: res.current_auto_param.FAN_T_MAX,
        FAN_T_MIN: res.current_auto_param.FAN_T_MIN,
        PUMP_FREQ_MAX: res.current_auto_param.PUMP_FREQ_MAX,
        PUMP_FREQ_MIN: res.current_auto_param.PUMP_FREQ_MIN,
        PUMP_T_MAX: res.current_auto_param.PUMP_T_MAX,
        PUMP_T_MIN: res.current_auto_param.PUMP_T_MIN,
        // fanTimeStep: res.current_auto_param.FAN_TIME_STEP_INTERVAL,
        // pumpTimeStep: res.current_auto_param.PUMP_TIME_STEP_INTERVAL,
        // fanMaxSpeed: res.current_auto_param.FAN_SPEED_MAX,
        // fanMinSpeed: res.current_auto_param.FAN_SPEED_MIN,
        // increaseFanSpeedCoefficient: res.current_auto_param.K_FAN_STEP_INC,
        // decreaseFanSpeedCoefficient: res.current_auto_param.K_FAN_STEP_DEC,
        // fanSpeedIncreaseStartTemp: res.current_auto_param.T_LEVEL_FAN_INC_ENABLE,
        // fanSpeedIncreaseStopTemp: res.current_auto_param.T_LEVEL_FAN_INC_DISABLE,
        // fanSpeedDecreaseStartTemp: res.current_auto_param.T_LEVEL_FAN_DEC_DISABLE,
        // fanSpeedDecreaseStopTemp: res.current_auto_param.T_LEVEL_FAN_DEC_ENABLE,
        // pumpMaxFreq: res.current_auto_param.PUMP_FREQ_MAX,
        // pumpMinFreq: res.current_auto_param.PUMP_FREQ_MIN,
        // increasePumpSpeedCoefficient: res.current_auto_param.K_PUMP_STEP_INC,
        // decreasePumpSpeedCoefficient: res.current_auto_param.K_PUMP_STEP_DEC,
        // pumpSpeedIncreaseStartTemp: res.current_auto_param.T_LEVEL_PUMP_INC_ENABLE,
        // pumpSpeedIncreaseStopTemp: res.current_auto_param.T_LEVEL_PUMP_INC_DISABLE,
        // pumpSpeedDecreaseStopTemp: res.current_auto_param.T_LEVEL_PUMP_DEC_DISABLE,
        // pumpSpeedDecreaseStartTemp: res.current_auto_param.T_LEVEL_PUMP_DEC_ENABLE,
        // maxFanSpeedTemperature: res.current_auto_param.FAN_T_MAX,
        // minFanSpeedTemperature: res.current_auto_param.FAN_T_MIN,
        // maxPumpSpeedTemperature: res.current_auto_param.PUMP_T_MAX,
        // minPumpSpeedTemperature: res.current_auto_param.PUMP_T_MIN,
      };
    },
    async fetchContainers() {
      const authStore = useAuthStore();

      const res = (
        await ContainersService.fetchAllContainers({
          token: authStore.token,
          cmd: CMD.CONTAINERS_LIST,
        })
      )?.data;

      if (!res) return;

      this.containerIdsArray = res.containers;
    },
    setContainerData(res, containerId) {
      if (!res) return;

      if (containerId) {
        const targetContainer = this.containers.find(
          (c) => c.containerId === containerId
        );

        if (!targetContainer) return;

        targetContainer.connectionNumber = `${res.hb}`
          .padStart(3, "0")
          .slice(-3);
        targetContainer.isConnected = res.connection;

        return;
      }

      this.containers.push({
        header: res.container_name,
        containerId: res.container_sn,
        connectionNumber: `${res.hb}`.padStart(3, "0").slice(-3),
        isConnected: res.connection,

        items: [
          {
            title: "Pump",
            type: CONTAINER_ITEM_TYPE.PUMP,
            status: STATUS.NORMAL,
            staffAmount: res.pump_freq.length,
          },
          {
            title: "Fan",
            type: CONTAINER_ITEM_TYPE.FAN,
            status: STATUS.NORMAL,
            staffAmount: res.fan_speed.length,
          },
          {
            title: "Tank",
            type: CONTAINER_ITEM_TYPE.TANK,
            status: STATUS.NORMAL,
            staffAmount: res.tanks_level.length,
          },
        ],
      });
    },
    async sendNewParameters(containerId) {
      if (this.manualUserModelData === null || this.autoUserModelData === null)
        return;

      const authStore = useAuthStore();

      ContainersService.sendContainerParams({
        token: authStore.token,
        cmd: CMD.SET_CONTAINER_PARAM,
        container_id: containerId,
        pin: this.pin || 0,
        auto_mode: this.currentUserMode ? 1 : 0,
        fan_speed: this.manualUserModelData.fanSpeed,
        pump_1_freq: this.manualUserModelData.pump1Freq,
        pump_2_freq: this.manualUserModelData.pump2Freq,

        FAN_SPEED_MAX: this.autoUserModelData.FAN_SPEED_MAX,
        FAN_SPEED_MIN: this.autoUserModelData.FAN_SPEED_MIN,
        FAN_T_MAX: this.autoUserModelData.FAN_T_MAX,
        FAN_T_MIN: this.autoUserModelData.FAN_T_MIN,
        PUMP_FREQ_MAX: this.autoUserModelData.PUMP_FREQ_MAX,
        PUMP_FREQ_MIN: this.autoUserModelData.PUMP_FREQ_MIN,
        PUMP_T_MAX: this.autoUserModelData.PUMP_T_MAX,
        PUMP_T_MIN: this.autoUserModelData.PUMP_T_MIN,
      });
    },
    async fetchSingleContainerData(containerId) {
      const authStore = useAuthStore();

      const res = (
        await ContainersService.fetchContainerData({
          token: authStore.token,
          cmd: CMD.CONTAINER_DATA,
          container_id: containerId,
        })
      )?.data;

      if (!res) return;

      this.setParameters(res);

      this.activeContainerId = containerId;
      this.connectionNumber = `${res.hb}`.padStart(3, "0").slice(-3);
      this.isConnected = res.connection;

      this.singleContainer = {
        leftContour: {
          pump: {
            title: "Pump " + res.pump_freq[0].p_num,
            status: STATUS.NORMAL,
            freq: res.pump_freq[0].freq,
          },
          fans: res.fan_speed.slice(0, 7).map((f) => {
            return {
              title: "Fan " + f.f_num,
              status: STATUS.NORMAL,
              speed: f.speed,
            };
          }),
          tanks: res.tanks_level.slice(0, 4).map((t, idx) => {
            return {
              title: "Tank " + t.t_num,
              inTemp: res.tanks_temperature[idx].t_in,
              outTemp: res.tanks_temperature[idx].t_out,
              status: t.t_level_0
                ? STATUS.WARNING
                : t.t_level_1
                ? STATUS.ERROR
                : STATUS.NORMAL,
            };
          }),
        },
        rightContour: {
          pump: {
            title: "Pump " + res.pump_freq[1].p_num,
            status: STATUS.NORMAL,
            freq: res.pump_freq[1].freq,
          },
          fans: res.fan_speed.slice(7).map((f) => {
            return {
              title: "Fan " + f.f_num,
              status: STATUS.NORMAL,
              speed: f.speed,
            };
          }),
          tanks: res.tanks_level.slice(4).map((t, idx) => {
            return {
              title: "Tank " + t.t_num,
              inTemp: res.tanks_temperature.slice(4)[idx].t_in,
              outTemp: res.tanks_temperature.slice(4)[idx].t_out,
              status: t.t_level_0
                ? STATUS.WARNING
                : t.t_level_1
                ? STATUS.ERROR
                : STATUS.NORMAL,
            };
          }),
        },
      };
    },
    async fetchAllContainersData() {
      if (!this.containerIdsArray.length) return;

      const authStore = useAuthStore();

      const promiseArr = this.containerIdsArray.map((id) =>
        ContainersService.fetchContainerData({
          token: authStore.token,
          cmd: CMD.CONTAINER_DATA,
          container_id: id,
        })
      );

      Promise.allSettled(promiseArr).then((res) =>
        res.forEach((r) => {
          if (r.status === "fulfilled") {
            // need's to prevent all model creation
            // and update only changed parameters in each container
            // it leads for better UI and re-render only parts what really need
            this.containers.length === this.containerIdsArray.length
              ? this.setContainerData(
                  r?.value?.data,
                  r?.value?.data.container_sn
                )
              : this.setContainerData(r?.value?.data);
          }
        })
      );
    },
  },
});
