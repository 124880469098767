<template>
  <nav class="side-menu">
    <ul class="side-menu__top-controls">
      <li 
        v-for="control in SIDE_MENU_CONTROLS.slice(0, 5)" 
        :key="control.type"
        :class="`side-menu__top-controls--item ${activeControlType === control.type ? 'active' : ''}`"
        @click="() => controlClickHandler(control.type)"
      >
        <img 
          alt="control" 
          :src="activeControlType === control.type ? control.activeIcon : control.passiveIcon" 
        />

        <span>{{ control.title }}</span>
      </li>
    </ul>

    <ul class="side-menu__bottom-controls">
      <li 
        v-for="control in SIDE_MENU_CONTROLS.slice(5)" 
        :key="control.type"
        :class="`side-menu__bottom-controls--item ${activeControlType === control.type ? 'active' : ''}`"
        @click="() => controlClickHandler(control.type)"
      >
        <img 
          alt="control" 
          :src="activeControlType === control.type ? control.activeIcon : control.passiveIcon" 
        />

        <span>{{ control.title }}</span>
      </li>
    </ul>
  </nav>

  <div class="fog-layout" />
</template>

<script setup lang="ts">
import { CONTROL_TYPES } from '@/core/types/components/enums';
import { ROUTER_URLS } from '@/core/types/router/enums';
import { SIDE_MENU_CONTROLS } from '@/core/constants/components';

import { useAuthStore } from '@/stores/authStore';

import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const route = useRoute();

const { logout } = useAuthStore();

const activeControlType = computed<CONTROL_TYPES | string>(() => {
  // calculations need's to prevent blinking of active state marker
  // when page reload
  const type = SIDE_MENU_CONTROLS.find(c => route.fullPath.includes(c.type))?.type;

  if (!type) return '';
  return type;
});

function controlClickHandler(type: CONTROL_TYPES) {
  switch (type) {
    case CONTROL_TYPES.CONTAINERS:
      router.push({ path: ROUTER_URLS.CONTAINERS });

      return;
    case CONTROL_TYPES.DASHBOARD:
      router.push({ path: ROUTER_URLS.HOME });

      return;
    case CONTROL_TYPES.ANALYTICS:
      router.push({ path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_CHANGING_PARAMETERS });

      return;
    case CONTROL_TYPES.LOGOUT:
      logout();

      router.push({ path: ROUTER_URLS.LOGIN });

      return;
    default:
      return;
  };
};
</script>

<style scoped lang="scss">
@import './SideMenu.scss';
</style>