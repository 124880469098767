export enum ANALYTICS_NAVIGATION_TYPES {
  CHANGE_PARAMETERS = "changing-parameters",
  OPERATION_MODE = "operation-mode",
  CONNECTION = "connection",
  PARAMETERS = "parameters",
  DETAILS = "details",
}

export enum ANALYTICS_COLORS_PALETTE {
  DARK_BLUE = "#181E36",
  BLUE = "#008FFB",
  LIGHT_BLUE = "#AEF9FF",
  GREEN = "#9BDEAC",
  DARK_GREEN = "#18AC9C",
  LIGHT_GREEN = "#00BADA",
  PINK = "#DC6BDB",
  LIGHT_PINK = "#F4B4D8",
  WHITE = "#FFF",
  BLACK = "#000",
}

export enum LEGEND_TYPES {
  CHANGE_PARAMETERS = "parameters",
  OPERATION_MODE = "mode",
  CONNECTION = "connection",
}
