export enum ROUTER_URLS {
    LOGIN = '/login',
    HOME = '/dashboard',
    CONTAINERS = '/containers',
    SINGLE_CONTAINER = '/containers/:id',
    ANALYTICS = '/analytics',
    ANALYTICS_OPERATION_MODE = '/operation-mode',
    ANALYTICS_CHANGING_PARAMETERS = '/changing-parameters',
    ANALYTICS_CONNECTION = '/connection',
    ANALYTICS_PARAMETERS = '/parameters',
    ANALYTICS_DETAILS = '/:analyticType/:detailsType',
};
